import { default as _91slug_93SLPQk3yu1tMeta } from "/home/forge/www.dogashop.com/pages/[slug].vue?macro=true";
import { default as addressfjkqdCOayeMeta } from "/home/forge/www.dogashop.com/pages/account/address.vue?macro=true";
import { default as _91id_93CZ4gnV0UGVMeta } from "/home/forge/www.dogashop.com/pages/account/orders/[id].vue?macro=true";
import { default as _91id_93Dpm9IUj06PMeta } from "/home/forge/www.dogashop.com/pages/account/orders/cancel/[id].vue?macro=true";
import { default as index2vVpGcHQI2Meta } from "/home/forge/www.dogashop.com/pages/account/orders/index.vue?macro=true";
import { default as review5Qy3aBaPIgMeta } from "/home/forge/www.dogashop.com/pages/account/review.vue?macro=true";
import { default as _91id_93CiqAoPsG7fMeta } from "/home/forge/www.dogashop.com/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexBL8KNS4dAeMeta } from "/home/forge/www.dogashop.com/pages/account/statement/index.vue?macro=true";
import { default as userZAe2m8J5iaMeta } from "/home/forge/www.dogashop.com/pages/account/user.vue?macro=true";
import { default as wishlistfqmfmaFWQ4Meta } from "/home/forge/www.dogashop.com/pages/account/wishlist.vue?macro=true";
import { default as cartdLRFtMtqSCMeta } from "/home/forge/www.dogashop.com/pages/cart.vue?macro=true";
import { default as indexmdEZvOtZCKMeta } from "/home/forge/www.dogashop.com/pages/checkout/index.vue?macro=true";
import { default as paymentb5q1AZi7NmMeta } from "/home/forge/www.dogashop.com/pages/checkout/payment.vue?macro=true";
import { default as summary_46client9YBM7lks10Meta } from "/home/forge/www.dogashop.com/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/www.dogashop.com/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestBIexV37GacMeta } from "/home/forge/www.dogashop.com/pages/customer/request.vue?macro=true";
import { default as success3WkQBzay4QMeta } from "/home/forge/www.dogashop.com/pages/customer/success.vue?macro=true";
import { default as iletisimC56JNRrmg4Meta } from "/home/forge/www.dogashop.com/pages/iletisim.vue?macro=true";
import { default as index935Mt0wqaQMeta } from "/home/forge/www.dogashop.com/pages/index.vue?macro=true";
import { default as logincYF5sGe4dvMeta } from "/home/forge/www.dogashop.com/pages/login.vue?macro=true";
import { default as offery5zK35K1IqMeta } from "/home/forge/www.dogashop.com/pages/offer.vue?macro=true";
import { default as statusfadbBGCESLMeta } from "/home/forge/www.dogashop.com/pages/order/status.vue?macro=true";
import { default as trackQmUaYOziElMeta } from "/home/forge/www.dogashop.com/pages/order/track.vue?macro=true";
import { default as forgotag0MxZ94J3Meta } from "/home/forge/www.dogashop.com/pages/password/forgot.vue?macro=true";
import { default as reset0UG1YbTcgvMeta } from "/home/forge/www.dogashop.com/pages/password/reset.vue?macro=true";
import { default as payment_45notificationTi3EmWvYEdMeta } from "/home/forge/www.dogashop.com/pages/payment-notification.vue?macro=true";
import { default as summarywJ6WHLDrkWMeta } from "/home/forge/www.dogashop.com/pages/payment/summary.vue?macro=true";
import { default as registerfFz2UOl8nCMeta } from "/home/forge/www.dogashop.com/pages/register.vue?macro=true";
import { default as searchwD267b2J1MMeta } from "/home/forge/www.dogashop.com/pages/search.vue?macro=true";
import { default as locations8BqKw1tq7pMeta } from "/home/forge/www.dogashop.com/modules/locationsPage/pages/locations.vue?macro=true";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93SLPQk3yu1tMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addressfjkqdCOayeMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_93CZ4gnV0UGVMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_93Dpm9IUj06PMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: index2vVpGcHQI2Meta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: review5Qy3aBaPIgMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_93CiqAoPsG7fMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexBL8KNS4dAeMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: userZAe2m8J5iaMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistfqmfmaFWQ4Meta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/www.dogashop.com/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: indexmdEZvOtZCKMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: paymentb5q1AZi7NmMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/www.dogashop.com/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/www.dogashop.com/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/www.dogashop.com/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/www.dogashop.com/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: index935Mt0wqaQMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: logincYF5sGe4dvMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offery5zK35K1IqMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/www.dogashop.com/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/www.dogashop.com/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgotag0MxZ94J3Meta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: reset0UG1YbTcgvMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/www.dogashop.com/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/www.dogashop.com/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: registerfFz2UOl8nCMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: searchwD267b2J1MMeta || {},
    component: () => import("/home/forge/www.dogashop.com/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/www.dogashop.com/modules/locationsPage/pages/locations.vue")
  }
]